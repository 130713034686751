import { BasePage } from "../base-page";
import strings from "../../assets/strings/strings.json";

export class LoginPage extends BasePage {

    constructor() {
        super();
        this.title = strings.login_title
      }


    render() {
        return <div className="base-container">
        </div>;
    }
}